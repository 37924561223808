var wipwrp = window.wipwrp || {};

wipwrp.domandalanding = (function($,mzr,doc,win){

	/* ----------------- private vars ---------------*/

	var	self				=	{};

	/* ----------------- public methods ---------------*/

	self.init = function(){
		$('html').removeClass('no-document-ready').addClass('document-ready');
		wipwrp.commons.relationField();
		$('form#form_landing').parsley();
		if ($('#fancy-landing').length) {
			$.fancybox.open({
				href : '#fancy-landing',
				minHeight: 30,
				maxWidth: 600,
				padding: 25,
				tpl : {
					closeBtn : '<a title="Close" class="fancybox-item fancybox-close icon icon-cancel" href="javascript:;"></a>',
				}
			});

		}
		$('[data-copy-input]').on('click', function (evt) {
			if (!navigator.clipboard) return;
			const target = evt.currentTarget;
			const value = $(target).find('input').val();
			navigator.clipboard.writeText(value).catch(function (err) {
				console.log(err)
			});
		});
	};
	/* ----------------- public methods ---------------*/

	return self;

})(jQuery,Modernizr, document, window);
